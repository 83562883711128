<template>
  <router-view v-if="standAlone"></router-view>
  <layout v-else>
    <template slot="layout-main">
      <router-view></router-view>
    </template>
  </layout>
</template>
<script>
export default {
  computed: {
    chartId () {
      return this.$route.query.chartId || ''
    },
    mode () {
      return this.$route.query.mode || ''
    },
    standAlone () {
      return this.$route.path === '/chart-detail' && this.chartId && this.mode === 'share'
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
